import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Terms from "./../T&C/T&C.js";
import Privacy from "./../T&C/Privacy&Policy.js";
import InsuranceTerms from "./../T&C/Insurance-tc";
import Estimate from "./../OrderForm/Estimate";
import Home from "./../Signup&Login/Home";
import DesktopLogin from "./../Signup&Login/DesktopLogin";
import DesktopSignup1 from "./../Signup&Login/DesktopSignup1";
import DesktopSignup2 from "./../Signup&Login/DesktopSignup2";
import DesktopSignup3 from "./../Signup&Login/DesktopSignup3";
import DesktopSignup4 from "./../Signup&Login/DesktopSignup4";
import DesktopBusinessSignup1 from "./../Signup&Login/DesktopBusinessSignup1";
import DesktopBusinessSignup2 from "./../Signup&Login/DesktopBusinessSignup2";
import DesktopBusinessSignup3 from "./../Signup&Login/DesktopBusinessSignup3";
import DesktopBusinessSignup4 from "./../Signup&Login/DesktopBusinessSignup4";
import MobileSignup from "./../Signup&Login/SignupMobile";
import Signupotp from "./../Signup&Login/Signupotp";
import Otp from "../Signup&Login/Otp";
import MobileSignup1 from "../Signup&Login/Signupmobile1";
import MobileSignin from "./../Signup&Login/SigninMobile";
import BusinessSignupMobile from "./../Signup&Login/BusinessSignupMobile";
import BusinessSignupOtp from "./../Signup&Login/BusinessSignupOtp";
import BusinessSignupMobile1 from "./../Signup&Login/BusinessSignupMobile1";
import BusinessSignupMobile2 from "./../Signup&Login/BusinessSignupMobile2";
import ForgotPassword from "./../Signup&Login/ForgotPassword";
import ResetPassword1 from "./../Signup&Login/ResetPassword";
import ContactUs from "./../Signup&Login/ContactUs";
import PromoCode from "./../OrderForm/PromoCode";
import PromocodePayment from "./../OrderForm/PromocodePayment";
import AddInsurance from "./../OrderForm/AddInsurance";
import PastOrders from "./../OrderForm/PastOrders";
import TrackCurrentOrder from "./../OrderForm/TrackCurrentOrder";
import CancelOrder from "./../OrderForm/CancelOrder";
import SignupMobileAs from "./../Signup&login-Mobile/MobileSignupAs";
import SigninMobile from "./../Signup&login-Mobile/MobileLoginPage1";
import SigninMobile1 from "./../Signup&login-Mobile/MobileLoginPage2";
import SignupMobile from "./../Signup&login-Mobile/MobileSignupPage1";
import SignupMobile1 from "./../Signup&login-Mobile/MobileSignupPage2";
import SignupMobile2 from "./../Signup&login-Mobile/MobileSignupPage3";
import SignupBusinessMobile1 from "./../Signup&login-Mobile/MobileBusinessSignup1";
import SignupBusinessMobile2 from "./../Signup&login-Mobile/MobileBusinessSignup2";
import SignupBusinessMobile3 from "./../Signup&login-Mobile/MobileBusinessSignup3";
import SignupBusinessMobile4 from "./../Signup&login-Mobile/MobileBusinessSignup4";
import SigninMobileOtp from "./../Signup&login-Mobile/MobileOtpLogin";
import SigninMobileOtp1 from "./../Signup&login-Mobile/MobileOtpLogin1";
import SignupMobileOtp from "./../Signup&login-Mobile/MobileOtpSignup";
import SignupMobileOtp1 from "./../Signup&login-Mobile/MobileOtpSignup1";
import SignupMobileOtp2 from "./../Signup&login-Mobile/MobileOtpSignup2";
import SignupBusinessMobileOtp1 from "./../Signup&login-Mobile/MobileBusinessOtpSignup1";
import SignupBusinessMobileOtp2 from "./../Signup&login-Mobile/MobileBusinessOtpSignup2";
import SignupBusinessMobileOtp3 from "./../Signup&login-Mobile/MobileBusinessOtpSignup3";
import SignupBusinessMobileOtp4 from "./../Signup&login-Mobile/MobileBusinessOtpSignup4";
import Slider from "./../Signup&Login/Slider";
import MobileFormPage3 from "./../Orderform-Mobile/MobileFormPage3";
import MobileFormPage4 from "./../Orderform-Mobile/MobileFormPage4.js";
import MobileFormPage5 from "./../Orderform-Mobile/MobileFormPage5.js";
import DesktopForm from "./../OrderForm/DesktopForm.js";
import MobileFormPage1 from "./../Orderform-Mobile/MobileFormPage1.js";
import MobileFormPage2 from "./../Orderform-Mobile/MobileFormPage2";
import Mobile_Estimate from "./../Orderform-Mobile/Estimate.js";
import MobilePromoCode from "./../Orderform-Mobile/MobilePromoCode";
import MobilePromoCodePayment from "./../Orderform-Mobile/MobilePromoCodePayment";
import AddInsuranceMobile from "./../Orderform-Mobile/AddInsuranceMobile";
import ResetPassword from "./../Components/Reset-Password/index.js";
import MobileForgotPassword from "./../Signup&login-Mobile/MobileForgotPassword";
import MobilePass from "./../Components/Reset-Password/mobilePass.js";
import MobileSignupPage1 from "./../Signup&login-Mobile/MobileSignupPage1";
import MobileLoginPage1 from "./../Signup&login-Mobile/MobileLoginPage1";
import MobileContactUs from "./../Signup&login-Mobile/MobileContactUs";
import TrackOrder from "./../Tracking/TrackOrder";
import TrackSidebar from "./../Tracking/TrackSiderbar";
import MobileTrackOrder from "./../TrackingMobile/MobileTrackOrder";
import MobilePastOrders from "./../Orderform-Mobile/MobilePastOrders";
import MobileCancelOrder from "./../Orderform-Mobile/MobileCancelOrder";
import MobileCureentOrders from "./../Orderform-Mobile/MobileCurrentOrders";
import MobileContactUs1 from "./../Signup&login-Mobile/MobileContactUs1";
import Contactus2 from "./../OrderForm/Contactus";
import TrackGps from "./../Admin/TrackGps";
import TestGps from "../Admin/TestGps.js";
import OrderStatus from "../Admin/OrderStatus.js";
import DeliveryPartnerTerms from "../T&C/DeliveryPartnerTerms";
import toggleServiceability from "../Admin/toggleServiceability.js"
import ElectionGuideline from "../Notices/ElectionGuideline.jsx";

class RouterFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      senderAdd: "",
      senderAdd1: "",
      senderBuilding: "",
      senderContact: "",
      senderName: "",
      senderFlat: "",
      orgLat: "",
      orgLng: "",
      receiverName: "",
      receiverFlat: "",
      receiverAdd: "",
      receiverAdd1: "",
      receiverBuilding: "",
      receiverContact: "",
      destLat: "",
      destLng: "",
      deliveryMethod: "secure",
      isInsured: false,
      files: [],
      insuranceAmount: "1",
      amount: "",
      saveaspickup: "",
      saveasdrop: "",
      insuranceEstimate: "",
      savedAddresspickup: [],
      savedAddressdrop: [],
      popup1: false,
      popup2: false,
      alreadyInsured: false
    };

    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.HomePage = this.HomePage.bind(this);
    this.SignupForm = this.SignupForm.bind(this);
    this.SignupForm2 = this.SignupForm2.bind(this);
    this.LoginForm = this.LoginForm.bind(this);
    this.OrderForm = this.OrderForm.bind(this);
    this.ForgotPasswordRoute = this.ForgotPasswordRoute.bind(this);
    this.ResetPasswordRoute = this.ResetPasswordRoute.bind(this);
    this.ContactUsRoute = this.ContactUsRoute.bind(this);
    this.TrackOrderRoute = this.TrackOrderRoute.bind(this);
    this.TrackCurrentOrderRoute = this.TrackCurrentOrderRoute.bind(this);
    this.TrackPastOrderRoute = this.TrackPastOrderRoute.bind(this);
    this.CancelOrderRoute = this.CancelOrderRoute.bind(this);
  }

  checkAuth1 = () => {
    const token = localStorage.getItem('token');
    if (token !== null) {
      return true;
    } else {
      return false;
    }
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  HomePage = () => {
    if (this.state.width <= 500) {
      return <MobileLoginPage1 />;
    } else {
      return <Home />;
    }
  };

  LoginForm = () => {
    if (this.state.width <= 500) {
      return <SigninMobile1 />;
    } else {
      return <DesktopLogin />;
    }
  };

  SignupForm = () => {
    if (this.state.width <= 500) {
      return <SignupMobile1 />;
    } else {
      return <DesktopSignup3 />;
    }
  };

  SignupForm2 = () => {
    if (this.state.width <= 500) {
      return <MobileSignupPage1 />;
    } else {
      return <DesktopSignup2 />;
    }
  };

  OrderForm = () => {
    if (this.state.width <= 500) {
      return <MobileFormPage1 {...this.state} />;
    } else {
      return <DesktopForm {...this.state} />;
    }
  };

  ForgotPasswordRoute = (props) => {
    if (this.state.width <= 500) {
      return <MobileForgotPassword {...props} />;
    } else {
      return <ForgotPassword {...props} />;
    }
  };

  ResetPasswordRoute = (props) => {
    if (this.state.width <= 500) {
      return <MobilePass {...props} />;
    } else {
      return <ResetPassword {...props} />;
    }
  };

  ContactUsRoute = (props) => {
    if (this.state.width <= 500) {
      return <MobileContactUs {...props} />;
    } else {
      return <ContactUs {...props} />;
    }
  };

  TrackOrderRoute = (props) => {
    if (this.state.width <= 500) {
      return <MobileTrackOrder {...props} />;
    } else {
      return <TrackOrder {...props} />;
    }
  };

  TrackCurrentOrderRoute = (props) => {
    if (this.state.width <= 500) {
      return <MobileCureentOrders {...props} />;
    } else {
      return <TrackCurrentOrder {...props} />;
    }
  };

  TrackPastOrderRoute = (props) => {
    if (this.state.width <= 500) {
      return <MobilePastOrders {...props} />;
    } else {
      return <PastOrders {...props} />;
    }
  };

  CancelOrderRoute = (props) => {
    if (this.state.width <= 500) {
      return <MobileCancelOrder {...props} />;
    } else {
      return <CancelOrder {...props} />;
    }
  };

  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy-policy" component={Privacy} />
          <Route exact path="/insurance-terms" component={InsuranceTerms} />
          <Route exact path="/election-guideline" component={ElectionGuideline} />
          <Route exact path="/deliverypartnerterms" component={DeliveryPartnerTerms} />
          {/* <Route exact path="/Home" component={this.HomePage} /> */}
          <Route
            exact
            path="/Home"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={this.HomePage} />;
            }}
          />
          <Route
            exact
            path="/placeOrder"
            render={() => {
              if (this.checkAuth1()) return <Route component={this.OrderForm} />;
              else return <Redirect to="/Home" />;
            }}
            {...this.state}
          />
          <Route exact path="/Estimate" render={() => {
            if (this.checkAuth1()) return <Route component={Estimate} />;
            else return <Redirect to="/Home" />;
          }} />
          {/* <Route exact path="/Signup1" component={DesktopSignup1} /> */}
          <Route
            exact
            path="/Signup1"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={DesktopSignup1} />;
            }} />
          {/* <Route exact path="/Signup2" component={this.SignupForm2} /> */}
          <Route
            exact
            path="/Signup2"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={this.SignupForm2} />;
            }} />
          {/* <Route exact path="/Signup3" component={this.SignupForm} /> */}
          <Route
            exact
            path="/Signup3"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={this.SignupForm} />;
            }} />
          {/* <Route exact path="/Signup4" component={DesktopSignup4} /> */}
          <Route
            exact
            path="/Signup4"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={DesktopSignup4} />;
            }} />

          <Route
            exact
            path="/Businesssignup1"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={DesktopBusinessSignup1} />;
            }} />

          <Route
            exact
            path="/Businesssignup2"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={DesktopBusinessSignup2} />;
            }} />

          <Route
            exact
            path="/Businesssignup3"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={DesktopBusinessSignup3} />;
            }} />

          <Route
            exact
            path="/Businesssignup4"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={DesktopBusinessSignup4} />;
            }} />
          {/* <Route exact path="/Signupmobile" component={MobileSignup} /> */}
          <Route
            exact
            path="/Signupmobile"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={MobileSignup} />;
            }} />
          {/* <Route exact path="/signupotp" component={Signupotp} /> */}
          <Route
            exact
            path="/signupotp"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={Signupotp} />;
            }} />
          {/* <Route exact path="/otp" component={Otp} /> */}
          <Route
            exact
            path="/otp"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={Otp} />;
            }} />
          {/* <Route exact path="/Signupmobile1" component={MobileSignup1} /> */}
          <Route
            exact
            path="/Signupmobile1"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={MobileSignup1} />;
            }} />

          <Route
            exact
            path="/BusinessSignupMobile"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={BusinessSignupMobile} />;
            }} />

          <Route
            exact
            path="/BusinessSignupOtp"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={BusinessSignupOtp} />;
            }} />

          <Route
            exact
            path="/BusinessSignupMobile1"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={BusinessSignupMobile1} />;
            }} />

          <Route
            exact
            path="/BusinessSignupMobile2"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={BusinessSignupMobile2} />;
            }} />

          {/* <Route
            exact
            path="/Forgotpassword"
            component={this.ForgotPasswordRoute}
          /> */}
          <Route
            exact
            path="/Forgotpassword"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={this.ForgotPasswordRoute} />;
            }} />
          {/* <Route exact path="/Resetpassword" component={ResetPassword1} /> */}
          <Route
            exact
            path="/Resetpassword"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={ResetPassword1} />;
            }} />
          <Route exact path="/Promocode" render={() => {
            if (this.checkAuth1()) return <Route component={PromoCode} />;
            else return <Redirect to="/Home" />;
          }} />
          <Route exact path="/Promocodepayment" render={() => {
            if (this.checkAuth1()) return <Route component={PromocodePayment} />;
            else return <Redirect to="/Home" />;
          }} />

          <Route exact path="/AddInsurance" render={() => {
            if (this.checkAuth1()) return <Route component={AddInsurance} />;
            else return <Redirect to="/Home" />;
          }} />

          <Route exact path="/PastOrders" render={() => {
            if (this.checkAuth1()) return <Route component={this.TrackPastOrderRoute} />;
            else return <Redirect to="/Home" />;
          }} />

          <Route exact path="/TrackCurrentOrder" render={() => {
            if (this.checkAuth1()) return <Route component={this.TrackCurrentOrderRoute} />;
            else return <Redirect to="/Home" />;
          }} />

          {/* <Route exact path="/Signinmobile" component={MobileSignin} /> */}
          <Route
            exact
            path="/Signinmobile"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={MobileSignin} />;
            }} />
          {/* <Route exact path="/Signin" component={this.LoginForm} /> */}
          <Route
            exact
            path="/Signin"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={this.LoginForm} />;
            }} />
          {/* <Route exact path="/mobilesignin" component={SigninMobile} /> */}
          <Route
            exact
            path="/mobilesignin"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SigninMobile} />;
            }} />
          {/* <Route exact path="/mobilesignin1" component={SigninMobile1} /> */}
          <Route
            exact
            path="/mobilesignin1"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SigninMobile1} />;
            }} />
          {/* <Route exact path="/mobilesignupas" component={SignupMobileAs} /> */}
          <Route
            exact
            path="/mobilesignupas"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SignupMobileAs} />;
            }} />
          {/* <Route exact path="/mobilesignup" component={SignupMobile} /> */}
          <Route
            exact
            path="/mobilesignup"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SignupMobile} />;
            }} />
          {/* <Route exact path="/mobilesignup1" component={SignupMobile1} /> */}
          <Route
            exact
            path="/mobilesignup1"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SignupMobile1} />;
            }} />
          {/* <Route exact path="/mobilesignup2" component={SignupMobile2} /> */}
          <Route
            exact
            path="/mobilesignup2"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SignupMobile2} />;
            }} />

          <Route
            exact
            path="/MobileBusinessSignup1"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SignupBusinessMobile1} />;
            }} />

          <Route
            exact
            path="/MobileBusinessSignup2"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SignupBusinessMobile2} />;
            }} />

          <Route
            exact
            path="/MobileBusinessSignup3"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SignupBusinessMobile3} />;
            }} />

          <Route
            exact
            path="/MobileBusinessSignup4"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SignupBusinessMobile4} />;
            }} />


          {/* <Route exact path="/mobilesigninotp" component={SigninMobileOtp} /> */}
          <Route
            exact
            path="/mobilesigninotp"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SigninMobileOtp} />;
            }} />
          {/* <Route exact path="/mobilesigninotp1" component={SigninMobileOtp1} /> */}
          <Route
            exact
            path="/mobilesigninotp1"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SigninMobileOtp1} />;
            }} />
          {/* <Route exact path="/mobilesignupotp" component={SignupMobileOtp} /> */}
          <Route
            exact
            path="/mobilesignupotp"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SignupMobileOtp} />;
            }} />
          {/* <Route exact path="/mobilesignupotp1" component={SignupMobileOtp1} /> */}
          <Route
            exact
            path="/mobilesignupotp1"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SignupMobileOtp1} />;
            }} />
          {/* <Route exact path="/mobilesignupotp2" component={SignupMobileOtp2} /> */}
          <Route
            exact
            path="/mobilesignupotp2"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SignupMobileOtp2} />;
            }} />

          <Route
            exact
            path="/MobileBusinessSignupOtp1"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SignupBusinessMobileOtp1} />;
            }} />

          <Route
            exact
            path="/MobileBusinessSignupOtp2"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SignupBusinessMobileOtp2} />;
            }} />

          <Route
            exact
            path="/MobileBusinessSignupOtp3"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SignupBusinessMobileOtp3} />;
            }} />

          <Route
            exact
            path="/MobileBusinessSignupOtp4"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={SignupBusinessMobileOtp4} />;
            }} />

          <Route exact path="/Slider" render={() => {
            if (this.checkAuth1()) return <Route component={Slider} />;
            else return <Redirect to="/Home" />;
          }} />
          <Route exact path="/Instructions" component={MobileFormPage2} />
          <Route exact path="/YourDetails" render={() => {
            if (this.checkAuth1()) return <Route component={MobileFormPage3} />;
            else return <Redirect to="/Home" />;
          }} />

          <Route exact path="/YourDetails" render={() => {
            if (this.checkAuth1()) return <Route component={MobileFormPage3} />;
            else return <Redirect to="/Home" />;
          }} />
          <Route exact path="/Sender-Details" render={() => {
            if (this.checkAuth1()) return <Route component={MobileFormPage4} />;
            else return <Redirect to="/Home" />;
          }} />
          <Route exact path="/Drop-Details" render={() => {
            if (this.checkAuth1()) return <Route component={MobileFormPage5} />;
            else return <Redirect to="/Home" />;
          }} />
          <Route exact path="/Mobile-Estimate" render={() => {
            if (this.checkAuth1()) return <Route component={Mobile_Estimate} />;
            else return <Redirect to="/Home" />;
          }} />
          <Route exact path="/Mobilepromocode" render={() => {
            if (this.checkAuth1()) return <Route component={MobilePromoCode} />;
            else return <Redirect to="/Home" />;
          }} />
          <Route
            exact
            path="/Mobilepromocodepayment"
            render={() => {
              if (this.checkAuth1()) return <Route component={MobilePromoCodePayment} />;
              else return <Redirect to="/Home" />;
            }}
          />

          <Route
            exact
            path="/MobileAddInsurance"
            render={() => {
              if (this.checkAuth1()) return <Route component={AddInsuranceMobile} />;
              else return <Redirect to="/Home" />;
            }}
          />

          {/* <Route exact path="/ContactUs" component={this.ContactUsRoute} /> */}
          <Route
            exact
            path="/ContactUs"
            render={() => {
              if (this.checkAuth1()) return <Redirect to="/placeOrder" />;
              else return <Route component={this.ContactUsRoute} />;
            }} />

          <Route exact path="/trackOrder" render={() => {
            if (this.checkAuth1()) return <Route component={this.TrackOrderRoute} />;
            else return <Redirect to="/Home" />;
          }} />
          <Route exact path="/tracksidebar" render={() => {
            if (this.checkAuth1()) return <Route component={TrackSidebar} />;
            else return <Redirect to="/Home" />;
          }} />
          <Route exact path="/cancelOrder" render={() => {
            if (this.checkAuth1()) return <Route component={this.CancelOrderRoute} />;
            else return <Redirect to="/Home" />;
          }} />

          {/* <Route exact path="/MobilePastOrders" render={() => {
            if (this.checkAuth1()) return <Route component={MobilePastOrders} />;
            else return <Redirect to="/Home" />;
          }} />

          <Route exact path="/MobileCurrentOrders" render={() => {
            if (this.checkAuth1()) return <Route component={MobileCureentOrders} />;
            else return <Redirect to="/Home" />;
          }} /> */}

          <Route exact path="/Contactus2" render={() => {
            if (this.checkAuth1()) return <Route component={Contactus2} />;
            else return <Redirect to="/Home" />;
          }} />
          {/* <Route exact path="/ContactUs1" component={MobileContactUs1} /> */}
          <Route exact path="/ContactUs1" render={() => {
            if (this.checkAuth1()) return <Route component={MobileContactUs1} />;
            else return <Redirect to="/Home" />;
          }} />
          <Route exact path="/TrackGps" render={() => {
            if (this.checkAuth1()) return <Route component={TrackGps} />;
            else return <Redirect to="/Home" />;
          }} />
          <Route exact path="/TestGps" render={() => {
            if (this.checkAuth1()) return <Route component={TestGps} />;
            else return <Redirect to="/Home" />;
          }} />
          <Route exact path="/OrderStatus" render={() => {
            if (this.checkAuth1()) return <Route component={OrderStatus} />;
            else return <Redirect to="/Home" />;
          }} />
          <Route exact path="/toggleServiceability" render={() => {
            if (this.checkAuth1()) return <Route component={toggleServiceability} />;
            else return <Redirect to="/Home" />;
          }} />
          {/* <Route exact path='/otp1' component={Otp1} /> */}
          {/* <Route exact path='/promocode' component={PromoCode} /> */}
          <Route path="/reset/:token" component={this.ResetPasswordRoute} />
          <Redirect to="/Home" />
        </Switch>
      </div>
    );
  }
}

export default RouterFile;
