import React, { Fragment } from "react";
import Navbar from "../Components/Navbar/Navbar";
import { Container } from "reactstrap";
import "./ElectionGuideline.css"

function ElectionGuideline() {
  return (
    <Fragment>
      <Navbar />
      <hr />
      <Container style={{ 'textAlign': 'left', paddingBottom: '10%' }}>
        <h1>Election Guidelines</h1>
        <br />
        <table>
          <tr>
            <th>SN</th>
            <th>Type Of Movement</th>
            <th>Documents Required</th>
            <th>Rules</th>
          </tr>
          <tr>
            <td>1</td>
            <td>Sale</td>
            <td>GST Invoice</td>
            <td>Rule 46 of the CGST Rules 2017</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Branch Transfer</td>
            <td>Delivery challan</td>
            <td>Rule 55 of the CGST Rules 2017</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Goods Sent on Approval</td>
            <td>Delivery challan</td>
            <td>Rule 55 of the CGST Rules 2017</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Job Work</td>
            <td>Delivery challan</td>
            <td>Rule 55 of the CGST Rules 2017</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Goods Sent for Exhibition</td>
            <td>Delivery challan</td>
            <td>Rule 55 of the CGST Rules 2017</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Repairing / Alteration / Hallmarking</td>
            <td>Delivery challan</td>
            <td>Rule 55 of the CGST Rules 2017</td>
          </tr>
        </table>

        <div style={{ marginTop: '5%' }}>
          <p style={{ fontWeight: 800 }}>DON'Ts ❌</p>
          <ol>
            <li>Transport illegal items (Eg. unaccounted cash, gold, etc. not allowed)</li>
            <li>Transport items without necessary supporting documentation.</li>
            <li>Falsify the nature, quantity, or value of the items in shipping documents.</li>
            <li>Underreport shipment value or fraudulent tax evasion.</li>
          </ol>
        </div>

        <div style={{ marginTop: '5%' }}>
          <p style={{ fontWeight: 800 }}>DOs ✅</p>
          <ol>
            <li>Ensure you carry out transactions with the correct credentials, permits, licenses, etc.</li>
            <li>Correctly Outline the HSN code, nature, quantity, and value of items</li>
            <li>Secure packaging to prevent tampering or damage</li>
            <li>Insure your shipments against loss, theft, or damage</li>
            <li>Track real-time shipment movement with Gordian tracking services</li>
          </ol>
        </div>

        <div class="instructions">
          <p style={{ fontWeight: 800, fontSize: 18, marginTop: '5%' }}>If the delivery executive is stopped by authorities during a delivery:</p>
          <ul>
            <li>Someone from Gordian's team will reach the point</li>
            <li>Device will be unlocked in his / her presence</li>
            <li>Package will be shown for investigation</li>
            <li>After the check and if no objection provided by the authorities, package will be placed back inside the locker</li>
            <li>Delivery will be completed as usual</li>
            <li>If your assistance is requested by the authorities, we request your cooperation as well.</li>
          </ul>
        </div>
        <br />
        <p style={{ fontWeight: 800 }}>Please note that Gordian Technologies Private Limited or any of its employees, directors, or delivery partners will not be responsible for any loss or damage caused.</p>
        <br />
        <p>Our commitment to you is to ensure that every order is delivered securely and smoothly! </p>
        <br />
        <p style={{ fontWeight: 800 }}>Thank you.</p>


      </Container>
    </Fragment >
  )
}
export default ElectionGuideline
